import React, { FC } from 'react';
import { Trans } from '@lingui/macro';

import './MaintenanceMode.scss';

const MaintenanceMode: FC = () => {
  return (
    <div className="MaintenanceContainer">
      <h1>503</h1>
      <h2>
        <Trans>Prace serwisowe.</Trans>
      </h2>
      <p>
        <Trans>Przepraszamy, strona jest chwilowo niedostępna z powodu prac serwisowych. Wracamy niebawem.</Trans>
      </p>
    </div>
  );
};

export default MaintenanceMode;
