import { useQuery } from '@apollo/client';
import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';

import NotFound from 'Components/NotFound/NotFound';
import { ProfileRedirectResponse, slugAliasRedirectQuery } from 'utils/graphql/slug';
import RedirectWithStatus from './RedirectWithStatus';
import isValidSlugAlias from 'utils/validate/validateSlugAlias';

const getRedirectData = (pathname: string, query: typeof useQuery): ProfileRedirectResponse | undefined => {
  const isSlugAlias = isValidSlugAlias(pathname);

  if (!isSlugAlias) {
    return;
  }

  const { data } = query<ProfileRedirectResponse>(slugAliasRedirectQuery, {
    variables: {
      pathname: pathname,
    },
  });

  return data;
};

const WildcardRoute: FC = () => {
  const { pathname } = useLocation();

  const redirectData = getRedirectData(pathname, useQuery);

  if (redirectData) {
    const response = redirectData.slugAliasRedirect;

    const { statusCode, pathname } = response;

    switch (statusCode) {
      case 301:
        return <RedirectWithStatus to={pathname} status={statusCode} />;
      case 404:
        return <NotFound />;
      default:
        return <RedirectWithStatus to={pathname} status={statusCode} />;
    }
  }

  return <NotFound />;
};

export default WildcardRoute;
