import { onError, ErrorResponse } from '@apollo/client/link/error';

function userSideError(networkError: ErrorResponse['networkError']): boolean {
  return Boolean(
    networkError && 'statusCode' in networkError && networkError.statusCode >= 400 && networkError.statusCode < 500
  );
}

function getHumanReadableErrorMessage(graphQLErrors: ErrorResponse['graphQLErrors'] = []): string {
  const [graphQLError] = graphQLErrors;
  return graphQLError && graphQLError.message ? graphQLError.message : '';
}

/**
 * This code tries to override networkError message with the message from the first
 * objects in graphQLErrors array if present.
 *
 * This has to be done because of compatibility with old error handlers in our codebase.
 *
 * TODO: Refactor error handlers to avoid using this middleware.
 */
export const errorMiddleware = onError(({ graphQLErrors, networkError }: ErrorResponse) => {
  const humanReadableErrorMessage = getHumanReadableErrorMessage(graphQLErrors);
  if (networkError && userSideError(networkError) && humanReadableErrorMessage) {
    networkError.message = humanReadableErrorMessage; // eslint-disable-line no-param-reassign
  }
  // eslint-disable-next-line eqeqeq
  if (
    typeof window !== undefined &&
    networkError &&
    'statusCode' in networkError &&
    networkError?.statusCode == 503 &&
    window.location.pathname !== '/'
  ) {
    window.location.href = '/';
  }

  if (
    typeof window !== undefined &&
    networkError &&
    'statusCode' in networkError &&
    networkError?.statusCode == 503 &&
    window.location.pathname === '/'
  ) {
    window.dispatchEvent(new Event('MAINTENANCE_MODE'));
  }
});
