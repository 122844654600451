export default function isValidSlugAlias(pathname: string) {
  const profilePath = 'profil/';

  // profiles with premium do not have `profil/` in account url
  const isStartFromSlash = pathname.startsWith('/');

  const hasExtension = /\.[a-zA-Z0-9]+$/.test(pathname);
  const replacement = isStartFromSlash ? '/' : profilePath;
  const hasSlash = pathname.replace(replacement, '').includes('/');

  return !hasSlash && !hasExtension;
}
